import { colors } from "./colors";
import { typography } from "./typography";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const customTheme = createTheme({
  palette: colors,
  typography: {
    ...typography,
  },
  shape: {
    borderRadius: 12,
  },

  // Global component overrides
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {},
        textColorPrimary: {
          minHeight: "20px",
          margin: "0rem 0.5rem",
          color: colors.common.black,
          padding: "10px 12x",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.primary.main,
          opacity: 0.5,
          fontWeight: "600",
          "&:hover": {
            opacity: 1,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: { padding: 0, margin: 0 },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          fontSize: "1rem",
          fontWeight: "600",
          boxShadow: `0 2px 0 0 ${colors.grey[400]}`,
        },
        sizeLarge: {
          fontSize: "1.125rem",
          padding: "12px 63px 12px 53px",
        },
        outlined: {
          border: `solid 2px ${colors.primary.main} !important`,
        },
        contained: {
          boxShadow: "none",
        },
        containedGreen: {
          boxShadow: "none",
          color: `${colors.common.black}`,
          backgroundColor: `${colors.primary.green}`,
          padding: "8px 16px",
          borderRadius: 50,
          "&:hover": {
            backgroundColor: `${colors.primary.green}`,
            color: `${colors.common.black}`,
          },
        },
        containedLinen: {
          boxShadow: "none",
          color: `${colors.text.secondaryPink}`,
          backgroundColor: `${colors.secondary.linen}`,
          padding: "8px 16px",
          borderRadius: 50,
          "&:hover": {
            backgroundColor: `${colors.secondary.linen}`,
            color: `${colors.text.secondaryPink}`,
          },
        },

        containedPurple: {
          boxShadow: "none",
          color: `${colors.common.white}`,
          backgroundColor: `${colors.primary.purple}`,
          padding: "8px 16px",
          borderRadius: 50,
          "&:hover": {
            backgroundColor: `${colors.primary.purple}`,
            color: `${colors.common.white}`,
          },
          "&:disabled": {
            opacity: "0.5",
            color: colors.common.white,
            backgroundColor: colors.primary.purple,
          },
        },
        text: {
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { variant: "curved" },
          style: {
            minWidth: "175px",
            minHeight: "50px",
            margin: "6px",
            boxShadow: "none",
            color: colors.common.white,
            backgroundColor: colors.primary.purple,
            padding: "8px 40px",
            borderRadius: "26% 61% 31% 41% / 54% 100% 37% 100%",
            "&:hover": {
              color: colors.common.white,
              backgroundColor: colors.primary.purple,
            },
            "&:disabled": {
              opacity: "0.5",
              color: colors.common.white,
              backgroundColor: colors.primary.purple,
            },
          },
        },
        {
          props: { variant: "curved", color: "secondary" },
          style: {
            minWidth: "175px",
            minHeight: "50px",
            margin: "6px",
            boxShadow: "none",
            color: colors.common.black,
            backgroundColor: colors.primary.green,
            padding: "8px 40px",
            borderRadius: "26% 61% 31% 41% / 54% 100% 37% 100%",
            "&:hover": {
              color: colors.common.black,
              backgroundColor: colors.primary.green,
            },
            "&:disabled": {
              opacity: "0.5",
              color: colors.common.black,
              backgroundColor: colors.primary.green,
            },
          },
        },
        {
          props: { variant: "basic" },
          style: {
            border: "solid 2px #e3e3e3",
            boxShadow: "none",
            color: colors.primary.main,
          },
        },
        {
          props: { variant: "basic", color: "white" },
          style: {
            borderRadius: "24px",
            padding: "16px 20px",
            border: "none",
            color: colors.primary.main,
            backgroundColor: colors.common.white,
            boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.04)",
          },
        },
        {
          props: { variant: "basic", color: "secondary" },
          style: {
            border: "solid 2px #e3e3e3",
            boxShadow: "none",
            color: colors.text.secondary,
          },
        },
        {
          props: { variant: "basic", color: "inherit" },
          style: {
            border: "solid 2px #e3e3e3",
            boxShadow: "none",
            color: "#000000",
          },
        },
        {
          props: { variant: "basic", color: "success" },
          style: {
            border: "solid 2px #e3e3e3",
            boxShadow: "none",
            color: colors.success.main,
          },
        },
        {
          props: { variant: "basic", color: "error" },
          style: {
            border: "solid 2px #e3e3e3",
            boxShadow: "none",
            color: colors.error.main,
          },
        },
        {
          props: { variant: "basic", color: "info" },
          style: {
            border: "solid 2px #e3e3e3",
            boxShadow: "none",
            color: colors.text.primary,
          },
        },
        {
          props: { variant: "basic", color: "warning" },
          style: {
            border: "solid 2px #e3e3e3",
            boxShadow: "none",
            color: colors.warning.primary,
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "curved" },
          style: {
            margin: "6px",
            boxShadow: "none",
            color: colors.primary.green,
            backgroundColor: colors.primary.purple,
            borderRadius: "46% 53% 59% 42% / 42% 48% 52% 58%",
            "&:hover": {
              color: colors.primary.green,
              backgroundColor: colors.primary.purple,
            },
          },
        },
        {
          props: { variant: "curved", color: "secondary" },
          style: {
            margin: "6px",
            boxShadow: "none",
            color: colors.primary.purple,
            backgroundColor: colors.primary.green,
            borderRadius: "46% 53% 59% 42% / 42% 48% 52% 58%",
            "&:hover": {
              color: colors.primary.purple,
              backgroundColor: colors.primary.green,
            },
          },
        },
        {
          props: { variant: "curved", color: "info" },
          style: {
            margin: "6px",
            boxShadow: "none",
            color: `${colors.text.secondaryPink}`,
            backgroundColor: `transparent`,
            borderRadius: "46% 53% 59% 42% / 42% 48% 52% 58%",
            "&:hover": {
              color: colors.primary.secondaryPink,
              backgroundColor: colors.secondary.linen,
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "32px",
          boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.03)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
        outlined: {
          border: "none",
        },
        input: {
          fontWeight: "600",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: `2px solid ${colors.primary.light}`,
          backgroundColor: colors.common.white,
          borderRadius: 12,
          "&.Mui-error": {
            borderColor: colors.error.main,
          },
        },
        input: {
          fontWeight: "600",
          fontFamily: "Inter",
        },
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderBottom: `solid 2px ${colors.grey[200]}`,
          boxShadow: "none",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: `0 2px 0 0 ${colors.grey[400]}`,
          padding: "20px",
          maxWidth: "900px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "linen" },
          style: {
            fontWeight: 600,
            color: colors.common.black,
            backgroundColor: "rgba(249, 239, 236, 0.5)",
            border: `2px solid ${colors.common.lightPink}`,
            "&:hover": {
              backgroundColor: colors.common.offwhite,
              borderColor: colors.primary.main,
            },
          },
        },
        {
          props: { variant: "green" },
          style: {
            fontWeight: 600,
            color: colors.common.white,
            backgroundColor: colors.text.secondaryGreen,
          },
        },
      ],
      styleOverrides: {
        outlined: {
          fontWeight: 600,
          color: colors.common.black,
          border: `2px solid ${colors.primary.main}`,
        },
      },
    },
  },
});

export default responsiveFontSizes(customTheme);
