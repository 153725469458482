import { createTheme } from "@mui/material/styles";

/*
  Color specifications
 */

const defaultTheme = createTheme();

export const colors = {
  ...defaultTheme.palette,
  common: {
    black: "#000000",
    white: "#ffffff",
    offwhite: "#fcfcfc",
    lightPink: "#F9F2EC",
  },
  background: {
    default: "#ffffff",
  },
  primary: {
    main: "#4C2E4F",
    purple: "#4C2E4F",
    green: "#D1FE4E",
    light: "rgba(206, 166, 245, 0.15)",
    contrastText: "#000",
    linen: "#F9EFEC",
  },
  secondary: {
    main: "#dafc6d",
    light: "#f1fec6",
    dark: "#75b878",
    linen: "#F9EFEC",
    pink: "#F44174",
    tealGreen: "#07685E",
  },
  grey: {
    50: "#f8f8f8",
    100: "#f1f1f1",
    400: "#e3e3e3",
    800: "#666666",
  },
  error: {
    main: "#ef5350",
    light: "#fbe8e8",
  },
  warning: {
    main: "#ffbf44",
  },
  success: {
    main: "#449c4d",
  },
  text: {
    primary: "#4C2E4F",
    secondary: "#a0a0a0",
    secondaryPink: "#F44174",
    secondaryGreen: "#07685E",
    tertiary: "#6a6a6a",
    neutralBlack: "#0A0A0A",
    green: "#D1FE4E",
    white: "#fff",
  },
};

export const cardColors = ["#F9F2EC"];
