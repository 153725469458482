import { ApolloClient, createHttpLink, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import cache from "./cache";
import nookies from "nookies";

/*
  For graphQl endpoint
 */
const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_HOST,
});

/*
  Error handler
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.warn(graphQLErrors);
  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      if (error.extensions.code === "UNAUTHENTICATED") {
        handleUnAuth();
        return;
      }
    }
  }

  if (
    networkError &&
    networkError.name === "ServerError" &&
    networkError.statusCode === 401
  ) {
    handleUnAuth();
  }
});

const handleUnAuth = () => {
  if (typeof window !== "undefined") {
    window.location.assign(
      `${window.location.origin}/login?redirect=${location.pathname}`
    );
  }
};

/*
  Set headers for all operations
 */
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext((context) => {
    let token;
    const data = nookies.get(context);
    token = data.token;
    return {
      headers: {
        auth: token,
        "Content-Type": "application/json",
      },
    };
  });
  return forward(operation);
});

/*
  Apollo client
 */
const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, errorLink, httpLink]),
});

export default client;
