import { useLazyQuery } from '@apollo/client';
import { CartCountContext } from '@contexts/CartContext';
import { GET_CART_COUNT } from '@operations/cart/queries';
import { publicIp } from 'public-ip';
import { useContext } from 'react';

const priceFormatter = (currency, amount) => {
  if (!currency) return amount;
  const convert = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  return convert.format(amount);
};

const useCartCount = () => {
  const { cartCount, updateCartCount: _updateCartCount } =
    useContext(CartCountContext);
  const [getCartCount] = useLazyQuery(GET_CART_COUNT, {
    onCompleted: (data) => {
      if (
        !isNaN(data.cartById?.no_of_items) &&
        cartCount !== data.cartById?.no_of_items
      )
        _updateCartCount(data.cartById.no_of_items);
    },
  });

  const updateCartCount = () => {
    const cartId = localStorage.getItem('cartId');
    if (cartId) getCartCount({ variables: { id: parseInt(cartId) } });
  };

  return { cartCount, updateCartCount };
};

const isValidPhoneNumber = (phone) => {
  //TODO: Improve, replace with regex
  return phone.length >= 11;
};

const formatPhoneNumber = (phone) => {
  return phone.replaceAll(' ', '').replaceAll('-', '');
};

const isValidEmail = (email = '') => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const isPasswordValid = (password = '') => {
  const re = '';
  return re.test(String(password).toLowerCase());
};
const onCopy = (text = '') => {
  navigator.clipboard.writeText(text);
};

const openExternalLink = (url) => {
  window.open(url, '_blank');
};

const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

const trimText = (text, length = 18) => {
  return text.length > length ? text.substring(0, length) + '...' : text;
};

const isProd = () => {
  const PROD_HOSTS = ['www.getnaturethings.com'];
  const host = (window.location && window.location.hostname) || '';
  return PROD_HOSTS.includes(host);
};

const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

const getClientIp = async () =>
  await publicIp.v4({
    fallbackUrls: ['https://ifconfig.co/ip'],
  });

const categoriesParamsDataToFilter = (items, tagData) => {
  const _tags = [];
  const light = [];
  const plant_category = [];
  const maintenance = [];
  const space = [];

  items?.forEach((item) => {
    switch (item?.type) {
      case 'plant_category':
        plant_category.push(item.name);
        break;

      case 'space':
        space.push(item.name);
        break;

      case 'maintainance':
        maintenance.push(item.name);
        break;

      case 'tag':
        {
          const tag = parseTagName(item.name, tagData);
          if (tag) {
            _tags.push(tag);
          }
        }
        break;

      case 'light_level':
        light.push(item.name);
        break;

      default:
        break;
    }
  });
  return {
    'plantInfo.plant_category': plant_category,
    'plantInfo.space': space,
    'plantInfo.maintenance': maintenance,
    'plantInfo.light': light,
    _tags: _tags,
  };
};

const parseTagName = (name, tagData) => {
  let parsedName = name;
  let tagValue = '';
  if (parsedName.split(' ')[1] === 'plants') {
    tagValue = parsedName?.split(' ')?.[0];
  } else {
    tagValue = parsedName;
  }
  const tagLabel = tagData?.filter((t) => t?.value === tagValue)?.[0]?.label;
  return tagLabel || '';
};

export {
  categoriesParamsDataToFilter,
  delay,
  formatPhoneNumber,
  getClientIp,
  getQueryParams,
  isPasswordValid,
  isProd,
  isValidEmail,
  isValidPhoneNumber,
  onCopy,
  openExternalLink,
  priceFormatter,
  trimText,
  useCartCount,
};
