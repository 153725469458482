import { isProd } from "../../utils/index";

// log the pageview with their URL
export const pageview = (url) => {
  if (isProd()) {
    window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
    window.gtag("config", "AW-331300454");
  }
};

// log specific events happening.
export const event = ({ action, params }) => {
  if (isProd()) {
    window.gtag("event", action, params);
  }
};
