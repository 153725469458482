import React, { createContext, useReducer, useEffect } from "react";
import { useRouter } from "next/router";
// import dynamic from "next/dynamic";

// const PhoneNumberAuthPopup = dynamic(() =>
//   import("@components/common/PhoneNumberAuthPopup")
// );

const initialState = {
  authDialog: false,
  showCloseButton: true,
  additionalElement: null,
  disableScrollLock: false,
};

const AuthDialogContext = createContext({
  authDialog: false,
  showCloseButton: true,
  additionalElement: null,
  disableScrollLock: false,
});

function authDialogReducer(state, action) {
  switch (action.type) {
    case "OPEN_AUTH_DIALOG":
      return {
        ...state,
        authDialog: true,
      };
    case "CLOSE_AUTH_DIALOG":
      return {
        ...state,
        authDialog: false,
        additionalElement: null,
        disableScrollLock: false,
        showCloseButton: true,
      };
    case "SET_AUTH_DIALOG_DATA":
      return {
        ...state,
        ...action.payload,
        authDialog: true,
      };
    default:
      return state;
  }
}

function AuthDialogProvider({ children }) {
  const [state, dispatch] = useReducer(authDialogReducer, initialState);
  const router = useRouter();

  const closeAuthDialog = () => dispatch({ type: "CLOSE_AUTH_DIALOG" });

  const openAuthDialog = () => dispatch({ type: "OPEN_AUTH_DIALOG" });

  const setAuthDialogData = (data) =>
    dispatch({ type: "SET_AUTH_DIALOG_DATA", payload: data });

  const setAuthDialog = (data) => {
    data ? openAuthDialog() : closeAuthDialog();
  };

  useEffect(() => {
    if (state.authDialog) {
      router.push("/login");
    }
  }, [state.authDialog]);

  return (
    <AuthDialogContext.Provider
      value={{
        ...state,
        openAuthDialog,
        closeAuthDialog,
        setAuthDialogData,
        setAuthDialog,
      }}
    >
      {children}
      {/* Commented previous code */}

      {/* <PhoneNumberAuthPopup
        open={state.authDialog}
        handleClose={() => closeAuthDialog()}
        authDialog={state.authDialog}
        showCloseButton={state.showCloseButton}
        additionalElement={state.additionalElement}
        disableScrollLock={state.disableScrollLock}
      /> */}
    </AuthDialogContext.Provider>
  );
}

export { AuthDialogProvider, AuthDialogContext };
