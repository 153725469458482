import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDpdbcfRKtE9hsweUhJGdR1F0BQc4AuZ9Y",
  authDomain: "nature-things-app.firebaseapp.com",
  projectId: "nature-things-app",
  storageBucket: "nature-things-app.appspot.com",
  messagingSenderId: "893540352364",
  appId: "1:893540352364:web:c0922922ffd94156d23349",
  measurementId: "G-HDCRM59QK7",
};

initializeApp(firebaseConfig);

const auth = getAuth();

export { auth };
