/*
  Typography specifications
 */
export const typography = {
  fontFamily: "Space Grotesk",
  secondaryFontFamily: "Inter",

  h1: {
    fontSize: "2.25rem !important",
    "@media (min-width: 900px)": {
      fontSize: "3rem !important",
    },
    fontWeight: "600",
  },

  h2: {
    fontSize: "35px",
    fontWeight: "bold",
  },

  h3: {
    fontWeight: "bold",
  },

  h4: {
    "@media (min-width: 900px)": {
      fontSize: "2.5em !important",
    },
    fontWeight: "600",
  },

  h5: {
    fontSize: "16px",
    "@media (min-width: 900px)": {
      fontSize: "1.375rem !important",
    },
    fontWeight: "600",
  },

  h5Inter: {
    display: "block",
    fontFamily: "Inter",
    fontSize: "16px",
    "@media (min-width: 900px)": {
      fontSize: "1.375rem !important",
    },
  },

  h5InterBold: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "600",
    "@media (min-width: 900px)": {
      fontSize: "1.375rem !important",
    },
  },

  h6: {
    "@media (min-width: 900px)": {
      fontSize: "1.125rem !important",
    },
    fontSize: "16px",
    fontWeight: "500",
  },

  h6Inter: {
    display: "block",
    fontFamily: "Inter",
    fontSize: "14px",
    "@media (min-width: 900px)": {
      fontSize: "1.125rem !important",
    },
  },

  h6InterBold: {
    display: "block",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    "@media (min-width: 900px)": {
      fontSize: "1.125rem !important",
    },
  },

  subtitle1: {
    fontSize: "1.25rem !important",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  subtitle2: {
    fontSize: "2rem !important",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  subtitle3: {
    fontSize: "1.75rem !important",
    fontWeight: "700",
  },
  text: {
    fontSize: "12px !important",
    fontWeight: "500",
    lineHeight: "22px",
  },
};
