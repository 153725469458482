import { gql } from "@apollo/client";

const GET_CART = gql`
  query CartById($id: Int!) {
    cartById(id: $id) {
      cart_items {
        id
        item_id
        no_of_items
        delivery_est
        product {
          name
          description
          image_url
          price
          brand
          tags
          sku
          sold_by
          delivery_price
          delivery_time
          currency
        }
      }
      delivery_price
      tax
      total
      currency
      no_of_items
      grand_total
      platform_fees
      care_subs_price
      discount_applied
    }
  }
`;

const GET_CART_COUNT = gql`
  query CartById($id: Int!) {
    cartById(id: $id) {
      no_of_items
    }
  }
`;

const GET_STRIPE_SESSION_STATUS = gql`
  query GetStripeSessionStatus($cartId: Int!) {
    getStripeSessionStatus(cart_id: $cartId)
  }
`;

export { GET_CART, GET_CART_COUNT, GET_STRIPE_SESSION_STATUS };
