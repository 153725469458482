import { createContext, useEffect, useReducer } from "react";
import { onAuthStateChanged } from "firebase/auth";

import nookies, { parseCookies } from "nookies";
import { auth } from "../../firebase";

import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "@operations/user/queries";
import Message from "@components/common/Message";
import aa from "search-insights";
import { getClientIp } from "@utils";

const initialState = {
  userData: null,
  authUser: null,
};

const UserDataContext = createContext({
  userData: null,
  authUser: null,
});

function userDataReducer(state, action) {
  switch (action.type) {
    case "AUTHENTICATED":
      return {
        ...state,
        authUser: action.payload,
      };
    case "RESET_USER_DATA":
      return {
        ...state,
        authUser: null,
        userData: null,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
}

function UserDataProvider({ children }) {
  const [state, dispatch] = useReducer(userDataReducer, initialState);

  const [loadUser, { refetch: refetchUserData }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.user) {
        setUserData(data.user);
        aa("setUserToken", `user_${data?.user?.id}`);
      }
    },
    onError: (error) => {
      Message.error("Session expired, please login.");
      console.log(error);
      setUserData(null);
    },
  });

  const authenticated = (authUser) =>
    dispatch({ type: "AUTHENTICATED", payload: authUser });

  const restUserData = () => dispatch({ type: "RESET_USER_DATA" });

  const setUserData = (userData) =>
    dispatch({ type: "SET_USER_DATA", payload: userData });

  useEffect(() => {
    setIPAddAsUserToken();
    onAuthStateChanged(auth, async (user) => {
      const cookies = parseCookies();
      if (user) {
        if (!cookies?.token) {
          const token = user.accessToken;
          nookies.set(undefined, "token", token);
        }
        authenticated(user);
        loadUser();
      } else {
        restUserData();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.authUser && !state?.userData) {
      refetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.authUser, state?.userData]);

  const setIPAddAsUserToken = async () => {
    try {
      const ip = await getClientIp();
      aa("setUserToken", `userIp_${ip.replaceAll(".", "-")}`);
    } catch (error) {
      console.log("Error occurred in setting up IP", error);
    }
  };

  return (
    <UserDataContext.Provider
      value={{
        ...state,
        refetchUserData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}

export { UserDataProvider, UserDataContext };
