import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { Alert, Snackbar } from "@mui/material";
import { withTheme } from "@emotion/react";

const GlobalMessage = ({ content, index, type }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={index > 0 ? { bottom: index * 80 } : null}
      open={Boolean(content)}
    >
      <Alert severity={type}>{content}</Alert>
    </Snackbar>
  );
};

class Message {
  static success(content, index) {
    this.message("success", content, index);
  }
  static warn(content, index) {
    this.message("warning", content, index);
  }
  static error(content, index) {
    this.message("error", content, index);
  }
  static message(type, content, index) {
    const container = document.createElement("div");
    document.body.appendChild(container);
    render(
      <GlobalMessage type={type} content={content} index={index} />,
      container
    );
    setTimeout(() => {
      unmountComponentAtNode(container);
      container.parentNode.removeChild(container);
    }, 3000);
  }
}

export default withTheme(Message);
